.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flexbox {
  &.row-child-margin-10 {
    > *:not(:last-child) {
      margin-right: 10px;
    }
  }

  &.column-child-margin-10 > *:not(:last-child) {
    margin-bottom: 10px;
  }
}

.custom-form {
  background: #ffffff;
  width: 100%;
  &.helper-form {
    min-height: 55px;
    margin-bottom: 10px;
  }
  .MuiInputLabel-formControl {
    top: -7px;
    &.Mui-focused,
    &.MuiFormLabel-filled {
      top: 0;
    }
  }
  .MuiOutlinedInput-input {
    padding: 12px;
  }
  .MuiInputBase-inputMultiline {
    padding: 0;
  }
}

.fc-daygrid-day {
  .fc-daygrid-event {
    white-space: normal;
  }
}

body,
div,
span,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
dt,
dd,
ul,
li,
ol,
input,
form,
button,
section,
article {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

label {
  font-weight: bold;
}

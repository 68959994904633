@import '~react-pro-sidebar/dist/css/styles.css';
@import './_variables.scss';

@mixin sidebar-menu($bg-color, $active-bg-color){
  .pro-sidebar {
    .pro-sidebar-inner {
      background-color: $bg-color;
    }
    .pro-menu {
      .pro-menu-item {
        &.active,
        &.active:hover,
        &.active:focus {
          > .pro-inner-item {
            background-color: $active-bg-color;
          }
        }
      }
      > ul > .pro-sub-menu {
        > .pro-inner-list-item {
          background-color: $bg-color;
          > div > ul {
            padding-top: 0;
          }
        }
        &.active,
        &.active:hover,
        &.active:focus {
          > .pro-inner-list-item {
            background-color: $active-bg-color;
          }
        }
      }
    }
  }
}

.mypage {
  @include sidebar-menu($mypage-menu-background, $mypage-active-menu-background)
}
.partner {
  @include sidebar-menu($partner-menu-background, $partner-active-menu-background)
}
.admin {
  @include sidebar-menu($admin-menu-background, $admin-active-menu-background)
}
.pro-sidebar {
  color: #ffffff;
  .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-header {
    border-bottom: none;
    box-shadow: 1px 0 5px rgba(0, 0, 0, .1);
  }
}
.pro-sidebar .pro-menu {
  margin: 0;
}
.pro-sidebar .pro-menu .pro-menu-item {
  margin: 0;
  .pro-inner-item {
    padding: 8px 20px;
  }
  &.active,
  &.active:hover,
  &.active:focus {
    > .pro-inner-item {
      font-weight: bold;
      color: white;
    }
  }
  &.sub-active,
  &.sub-active:hover,
  &.sub-active:focus {
    > .pro-inner-item {
      font-weight: bold;
    }
  }
  .pro-inner-item {
    &:hover {
      outline: none;
      box-shadow: none;
      font-weight: bold;
      color: #ffffff;
    }
    &:focus {
      color: #ffffff;
    }
  }
}

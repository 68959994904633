// Sass変数を集約するファイル

$drawer-width: 233;

$mypage-menu-background: #9999CB;
$mypage-active-menu-background: #7A7AA2;

$partner-menu-background: #777FAD;
$partner-active-menu-background: #40476D;

$admin-menu-background: #9B9B9E;
$admin-active-menu-background: #585864;

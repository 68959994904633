.print-preview-page {
  @media print {
    .setting-form {
      display: none;
    }
    .print-area {
      box-shadow: none;
    }
  }

  .print-area {
    width: 172mm;
    height: 251mm;
    page-break-after: always;
  }
}

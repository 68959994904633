@import './custum_react_pro_sidebar_style.scss';
@import './_variables.scss';

.root {
  display: flex;
  .app-bar {
    z-index: 999;
    background-color: #ffffff;
    box-shadow: 1px 0 5px rgba(0, 0, 0, .1);
  }
  .drawer {
    width: #{$drawer-width}px;
    min-width: #{$drawer-width}px;
    height: 100%;
    position: fixed;
    flex-shrink: 0;
    z-index: 999;
  }
  .drawer-paper {
    max-width: #{$drawer-width}px;
    width: fit-content;
    opacity: 0.8;
    background: #000;
    color: #fff;
    .list-item-inner {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 8px 10px;
      border-radius: 3px;
      &.active,
      &.active:hover,
      &.active:focus {
        box-shadow: 0 12px 20px -10px rgba(0, 172, 193, 0.28), 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 172, 193, 0.2);
        background-color: #00acc1;
      }
      &:hover {
        outline: none;
        box-shadow: none;
        background-color: rgba(200, 200, 200, 0.2);
      }
      .MuiListItemIcon-root {
        color: #fff;
        min-width: 30px;
        margin-right: 5px;
      }
    }
  }
  .content {
    flex-grow: 1;
    padding: 0 10px 0 10px;
    width: calc(100% - 230px);
    min-width: 1000px;
    margin-left: #{$drawer-width}px;
    .MuiTable-root {
      border-radius: 4px;
      overflow: hidden;
    }
    .toggle-button-group {
      background-color: #ffffff;
    }
  }
  .toolbar {
    min-height: 64px;
    display: flex;
    align-items: center;
  }
  .MuiButtonBase-root {
    outline: none !important;
  }
}

@media print {
  .root {
    .drawer,
    .toolbar,
    .app-bar {
      display: none;
    }
    .content {
      margin: 0;
      padding: 0;
      min-width: 0;
    }
  }
  .notification-snackbar,
  .error-snackbar {
    display: none !important;
  }
}


.login {
  display: flex;
  justify-content: center;
  padding-top: 300px;
}
.login-area {
  width: 300px;
  padding: 30px;
}
.login-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.login-form > * {
    margin-top: 20px;
}
.MuiTextField-root {
  width: 100%;
}